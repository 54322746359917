
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;400&display=swap');

body{
  /* background-color: #000000; */
  overflow-x: hidden;
  background-image: radial-gradient(circle, #000000, #010715, #020212, #000000);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.body{
  height: fit-content;
  margin: 0 auto;
  /* background-color: #000000; */
  overflow-x: hidden;
  /* background-image: url('./assets/bg.jpg'); */
  background-size: cover;
  background-attachment: fixed;
}

/* General Effects */

.nolink{
  color: #00FF66;
  text-decoration: none;
}

.nolink:hover{
  color: #ffffff;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #00FF66; */
  background: #81D7D3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #00FF66; */
  background: #81D7D3;
}


/* --------------- */


.gola{
  display:block;
  top: 5%;
  position: absolute;
  height: 100%;
  z-index: 0;
}

.goldaba{
  display:block;
  position: relative;
}

/* Navbar stuff */

.navbox{
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.navbar-bg{
  background-color: #81D7D3;
  box-shadow: 0 0 2px 2px #81d7d3;
  font-family: 'Maven Pro', sans-serif;
}

.navlist{
  list-style-type:none;
}

.nav-link{
  color: #0affdef8 !important;
  user-select: none;
}

.bg-nav{
  /* background: rgba(6, 4, 4, 0.4);
  border-bottom: 1px solid rgba(18, 16, 16, 0.5);
  z-index: 1000; */
  background-color: rgba(0, 0, 0, .85);  
  backdrop-filter: blur(5px);
  /* background-color: #000000; */
}


/* hover Animation underline */

.nav-link:hover{
  color:  #08bbb2 !important;   /*#0087ca*/
  text-shadow: 0 0 5px #01f0b4;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0febe0;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.1px;
  bottom: 0;
  left: 0;
  background-color: #0febe0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  text-shadow: 0 0 5px #01f0b4;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Hover animation done */

/* Navbar Stuff Done */


/* Landing CSS */

#landing{
  height: 90vh;
  /* background-color: #000000; */
}

.intro-box{
  /* margin: 5%; */
  align-self: center;
}

.head-font{
  font-family: 'IBM Plex Mono', monospace;
  /* color: #00FF66; */
  /* color:rgb(1, 255, 242); */
  user-select: none;
  font-weight: 400;
}

.landing-bg{
  /* ; */
  position: relative;
  /* background-color: #203a85; */
}

.marg-down{
  margin-top: 14.5%;
}

.particleBack{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0
}

/* Landing CSS ends */  



/* About Page css */
#aboutme{
  height: 100vh;
  padding-top: 100px; 
  margin-top: 0%;
  padding-top: 12%;
}
.about_bg{
  /* background-color: #000000; */
}
.about-font{
  font-family: 'IBM Plex Mono', monospace;
  color: rgb(22, 25, 155);
  user-select: none;
}

.about-font:hover{
  text-shadow: 0 0 40px #01f0b4;;
}

.content-font-about{
  font-family: 'IBM Plex Mono', monospace;
  font-size: 120%;
  text-align: left;
  color: #1be9ab;
}

.content-box{
  width: 400%;
}

.hover-underline-animation2 {
  display: inline-block;
  position: relative;
  color: rgb(22, 25, 155);
}

.hover-underline-animation2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.1px;
  bottom: 0;
  left: 0;
  background-color: #0febe0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  text-shadow: 0 0 5px #01f0b4;
}

.hover-underline-animation2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
  
}

/* About Page ends */

/* Projects Section starts */

#projects{
  height: 100vh;
  margin-bottom: 10%;
  padding-top: 100px;
}

.bg-card{
  /* background-color: #44023f !important; */
  background-color: #0a1d46 !important;
}

.zoom{
  width: 18rem !important;
  transition: transform .2s;
}

.zoom:hover{
  transform: scale(1.2);
}

.card-title{
  color: #b4ecf0;
  font-family: 'IBM Plex Mono', monospace;
}

.card-title:hover{
  text-shadow: 0 0 5px #01f0b4;
}

.hover-underline-animation2 {
  display: inline-block;
  position: relative;
  color: rgb(0, 255, 234);
}

.hover-underline-animation3:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.1px;
  bottom: 0;
  left: 0;
  background-color: #1f6eb8;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  text-shadow: 0 0 5px #1f6eb8;
}

.hover-underline-animation3:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.card-text{
  text-align: left;
  color: #e0eee5;
}

.card-text:hover{
  text-shadow: 0 0 5px #01f0b4;
}

.fix{
  height: 150px !important;
}

.tl{
  text-align: left;
}

.tl2{
  text-align: right;
}

.ts{
  user-select: none;
}

/* Projects Page done */

/* Contacts Section goes here  */

#contacts{
  height: 90vh;
  padding-top: 100px;
}

.size-img{
  align-self: center;
  width: 12rem;
  height: 12rem;
  user-select: none;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.size-img:hover{
  transform: scale(1.1);
}

.size-img-ln{
  align-self: center;
  width: 10rem;
  height: 10rem;
  user-select: none;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.size-img-ln:hover{ 
  transform: scale(1.1);
}

.tx{
  font-family: 'Maven Pro', sans-serif;
  color:#bbffea;
  text-align: center;
  text-decoration: none;
}

.tx:hover{
  cursor:pointer;
  color:#f8f8f8;
}

.tx-lv{
  margin-left: 20px !important;
}
.text-col{
  font-family: 'Maven Pro', sans-serif;
  color:#18bbe4 !important;
  text-decoration: none;
  cursor: pointer;
}

.ht{
  height: 100px;
}

.cent{
  text-align: center;
  align-items: center;
}

.glow{
  text-shadow: 0px 1px #c6e9f7;
}

.typewriter{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(157, 231, 231); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  color: rgb(157, 231, 231);
  animation: 
    typing 6.5s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 95% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgba(167, 249, 241, 0); }
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* -------------------------- */

/* Media Queries */


@media  screen and (max-width: 1025px) {

  #landing{
    margin-top:0%;
    height: 70vh;
  }

  #aboutme{
    margin-top: 40%;
  }


  #projects{
    margin-top: 10%;
  }

  #contacts{
    margin-top: 60%;
    height: 400%;
  }

  .marg-up{
    margin-top: 20%;
  }
  
}

@media  screen and (max-width: 768px) {

  #landing{
    margin-top:1%;
    height: 70vh;
  }  

  #aboutme{
    margin-top: 70%;
  }

  #projects{
    margin-top: 150%;
  }

  #contacts{
    margin-top: 280%;
    height: 400%;
  }

  .marg-up{
    margin-top: 20%;
  }
  .typewriter{
    font-size: 0.25rem;
  }
}

/* ------------- */

.footerfont{
  font-family: 'Maven Pro', sans-serif;
  font-size: 150%;
  text-align: center;
  color:#18bbe4;
}